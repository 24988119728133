<template>
  <div>
    <el-form size="mini" ref="visitFormAdd" :model="visitFormAdd"  label-width="120px">
                <el-form-item label="当前访视:" prop="name">
                    <span>{{visitFormAdd.name}}</span>
                </el-form-item>
                <el-form-item v-if="visitFormAdd.type ==='1' " label="计划访视时间:" prop="id">
                    <span>{{visitFormAdd.plannedTime?visitFormAdd.plannedTime:'无'}}</span>
                </el-form-item>
                <el-form-item label="实际访视时间:"   prop="visitDate"  :rules="[{ required: true, message: '请选择实际访视时间', trigger: 'blur' }]">
                    <el-date-picker
                            v-model="visitFormAdd.visitDate"
                            type="datetime"
                            placeholder="选择日期时间"
                            @change="pickTime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item v-if="visitFormAdd.type ==='1' " label="" prop="id">
                    <span v-if="visitFormAdd.visitDateStatus !='' " :style="{color:(visitFormAdd.visitDateStatus==='1'?'green':'red')}">{{visitFormAdd.visitDateStatus==='1'?'访视时间正常':'访视时间超窗'}}</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button size="mini" @click="cancelToast">取 消</el-button>
                      <!--<el-button v-show="visitTitle==='添加表单'" size="mini" type="primary"-->
                      <!--@click="addFormConfirm('visitFormAdd')" :disabled="submitButtonDisabled">确 定</el-button>-->
              <el-button  size="mini" type="primary" @click="addVisitFormConfirm('visitFormAdd')" :disabled="submitButtonDisabled">保 存</el-button>
            </div>
  </div>
</template>

<script>
  export default {
    props:['visitFormAdd','visibleAdd','submitButtonDisabled'],
    methods: {
      pickTime(val){
        this.$emit('pickTime',val)
      },
      addVisitFormConfirm(formName){
        this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$emit('addVisitFormConfirm')
                    } else {
//                        console.log('error submit!!');
                        return false;
                    }
                });
        
      },
      cancelToast(){
        this.$emit('cancelToast',false)
      }
    }

  }
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>