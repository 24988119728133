<template>
  <div id="formEntry">
    <div class="common-header">
      <i
        class="el-icon-arrow-left visit-back"
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      ></i>
      <span>{{ researchName }}</span>
    </div>
    <el-row style="flex: 1; overflow-y: hidden; margin-top: 10px">
      <el-col
        :span="4"
        style="
          min-width: 150px;
          height: 100%;
          overflow: scroll;
          padding-bottom: 20px;
        "
      >
        <div class="formNav">
          <!-- <span class="formNav-label">受试者编号：</span> -->
          <el-form>
            <el-form-item label="受试者编号" label-width="90px">
              <el-select
                v-model="testeeValue"
                filterable
                placeholder="请选择"
                size="mini"
                @change="testeeChange"
              >
                <el-option
                  v-for="testeeItem in testeeList"
                  :key="testeeItem.pkId"
                  :label="testeeItem.outpatientNumber"
                  :value="testeeItem.pkId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-tree
            :data="treeData"
            :highlight-current="Boolean(true)"
            node-key="exampleId"
            :indent="0"
            :expand-on-click-node="true"
            :default-expanded-keys="[$route.query.exampleId]"
            :default-checked-keys="[defaultCheckTreeNode]"
            :current-node-key="defaultCheckTreeNode"
            class="tree-line"
            @node-expand="checkChange"
            @node-click="handleNodeClick"
            style="margin-top: 10px"
          >
            <span
              class="custom-tree-node"
              slot-scope="{ data }"
              :class="{ 'jump-red': data.skipFlag == 0 }"
            >
              <el-tooltip
                v-if="data.displayNameCn && data.displayNameCn.length > 12"
                class="item"
                effect="dark"
                :content="data.displayNameCn"
                placement="top-start"
              >
                <p
                  :class="{
                    'color-tree': data.sdvExist && $user.includes('CRA'),
                  }"
                >
                  <i
                    v-if="data.sdvExist && $user.includes('CRA')"
                    class="el-icon-question color-tip"
                  ></i
                  >{{ data.displayNameCn.slice(0, 12) + "..." }}
                </p>
              </el-tooltip>
              <p
                v-else
                :class="{
                  'color-tree': data.sdvExist && $user.includes('CRA'),
                }"
              >
                <i
                  v-if="data.sdvExist && $user.includes('CRA')"
                  class="el-icon-question color-tip"
                ></i
                >{{ data.displayNameCn }}
              </p>
            </span>
          </el-tree>
        </div>
      </el-col>
      <el-col
        v-loading="formLoading"
        :span="20"
        style="
          height: 100%;
          background: #f7f7f7;
          display: flex;
          overflow: scroll;
        "
        ref="col-wrap"
        class="col-wrap-s"
      >
        <el-card style="flex: 1; overflow-y: scroll; box-sizing: border-box">
          <ul class="form-header">
            <li>
              <p>项目名称</p>
            </li>
            <li>
              <p>中心名称</p>
            </li>
            <li>
              <p>受试者编号</p>
            </li>
            <li>
              <p>访视名称</p>
            </li>
            <li>
              <el-popover
                placement="top-start"
                width="250"
                trigger="hover"
                :content="projectName"
              >
                <span slot="reference">{{ projectName }}</span>
              </el-popover>
            </li>
            <li>
              <span>{{ centerName }}</span>
            </li>
            <li>
              <span>{{ outpatientNumber }}</span>
            </li>
            <li>
              <span>{{ handleItem.parentSectionName || handleItem.name }}</span
              ><span v-if="plannedVisitInfo.actualTime"
                >({{ plannedVisitInfo.actualTime }})</span
              >
            </li>
          </ul>
          <p class="form-title">{{ headerFormName }}</p>
          <div
            class="formStatus"
            v-if="
              treeData.length !== 0 &&
              (!handleItem.status || handleItem.status == 0)
            "
            v-authorizeRole="{
              itemCode: 'crf_mark',
              menuContainer: $authorContainer,
            }"
          >
            <span style="font-weight: bold">标记该表单为：</span>
            <el-select
              v-model="handleItem.status"
              filterable
              placeholder="请选择"
              @change="markStatus"
              size="small"
            >
              <el-option label="请选择" :value="0"></el-option>
              <el-option label="未做/未检测" :value="1"></el-option>
              <el-option label="不知道" :value="2"></el-option>
              <el-option label="尚未获取" :value="3"></el-option>
              <el-option label="不适用" :value="4"></el-option>
            </el-select>
            <el-popconfirm
              title="您确定要一键SDV吗？"
              style="float: right"
              @confirm="setFormSdv"
              v-show="isShowBTN"
            >
              <el-button slot="reference">一键SDV</el-button>
            </el-popconfirm>
          </div>
          <!--"treeData.length!==0&&handleItem.status!==0"-->
          <div
            class="formStatus"
            v-authorizeRole="{
              itemCode: 'crf_mark',
              menuContainer: $authorContainer,
            }"
            v-else
          >
            <span style="font-weight: bold; margin-right: 8px"
              >该表单已标记为{{ formStatusLabel[handleItem.status || 0] }}</span
            >
            <el-button type="text" @click="markStatus(0)">取消标记</el-button>
          </div>
          <!-- 单表单 -->
          <div
            class="formCon"
            v-if="handleItem && handleItem.selectionType === 'Single'"
            v-loading="loading"
          >
            <FormEntryFormSingle
              :formData="dynamicForm"
              :dialogStatus="dialogStatus"
              :onload="isOnload"
              formType="Single"
              :isShowBTN="isShowBTN"
              :isFormPreview="false"
              ref="singleForm"
              :formStatus="handleItem.status"
              @refresh="refresh"
              @refreshTable="refreshTable"
              @onTrailList="onTrailList"
              @backtop="backtop"
              @handleNodeClick="handleNodeClick"
              @refashZY="refashZY"
              @handleSubmitConfirm="handleSubmitConfirm"
              @updateDynamicForm="updateDynamicForm"
              :sectionList="sectionList"
              :parentExampleId="parentExampleId"
              :exampleId="handleItem.exampleId"
              :submitLoading="submitLoading"
              :isIndex="isIndex"
              :researchCode="researchCode"
              :editKey="editKey"
              :handleItem="handleItem"
              :nextItem="nextItem"
              :changePatientId="changePatientId"
            >
            </FormEntryFormSingle>
          </div>
          <!-- 多表单 -->
          <div
            v-if="handleItem && handleItem.selectionType === 'Multiple'"
            class="formCon table-wrap"
          >
            <el-table
              :data="formDtataList"
              border
              style="width: 100%"
              :header-cell-class-name="tableHeaderCn"
            >
              <template>
                <el-table-column
                  v-for="(col, colIdx) in cols"
                  :key="col.ddCode"
                  :label="col.ddName"
                  align="center"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    <div class="tag-top-wrap">
                      <span>{{ formatterScope(scope, colIdx) }}</span>
                      <el-popover placement="top" trigger="click">
                        <div class="table-e-wrap">
                          <!-- <p style="padding-right:8px;" @click="handleNote(scope.row[colIdx],1)">Note</p> -->
                          <p
                            class="icont-box"
                            style="padding-right: 8px"
                            :class="[
                              scope.row[colIdx].remark
                                ? 'icon-note1'
                                : 'icon-note0',
                            ]"
                            @click="handleNote(scope.row[colIdx], 1)"
                          ></p>

                          <div
                            v-authorizeRole="{
                              itemCode: 'crf_data_sdv',
                              menuContainer: $authorContainer,
                            }"
                            v-if="scope.row[colIdx].sdv === 'true' && isShowBTN"
                          >
                            <p
                              class="icont-box"
                              :class="[
                                scope.row[colIdx].sdvStatus
                                  ? 'icon-sdv1'
                                  : 'icon-sdv0',
                              ]"
                              @click="onChangeSDV(scope.row[colIdx])"
                              size="mini"
                            ></p>
                          </div>
                          <i
                            class="question-icon-wrap"
                            :class="[
                              'icon' +
                                (scope.row[colIdx].question
                                  ? scope.row[colIdx].question
                                  : 0),
                            ]"
                            @click="onQuestion(scope.row[colIdx])"
                          ></i>
                          <p
                            class="icont-box"
                            style="margin-left: 8px"
                            @click="onTrailList(scope.row[colIdx])"
                            :class="[isShowBTN ? 'icon-trail1' : 'icon-trail0']"
                          ></p>
                          <!-- <p v-if="scope.row[colIdx].sdv === 'true' && isShowBTN"
                                                    v-authorizeRole="{'itemCode':'crf_data_sdv',menuContainer:$authorContainer}">
                                                        <el-button class="qabtn" v-if="!scope.row[colIdx].sdvStatus"
                                                                type="primary" @click="onChangeSDV(scope.row[colIdx])"
                                                                size="mini">待SDV
                                                        </el-button>
                                                        <el-button class="qabtn" v-else type="success"
                                                                @click="onChangeSDV(scope.row[colIdx])" size="mini">已SDV
                                                        </el-button>
                                                    </p> -->
                          <!-- <i class="question-icon-wrap"
                                                    :class="['icon'+(scope.row[colIdx].question ? scope.row[colIdx].question:0)]"
                                                    @click="onQuestion(scope.row[colIdx])"></i>
                                                    <p class="icont-box"  style="margin-left:8px;" @click="onTrailList(scope.row[colIdx])" :class="[isShowBTN?'icon-trail1':'icon-trail0']"></p> -->
                        </div>
                        <i
                          type="primary"
                          style="cursor: pointer"
                          class="el-icon-more"
                          slot="reference"
                        ></i>
                      </el-popover>
                    </div>
                    <div
                      class="note-box-wrap"
                      style="
                        padding-left: 0;
                        padding-top: 8px;
                        color: #909399;
                        text-align: left;
                      "
                      v-if="scope.row[colIdx].remark"
                    >
                      <p class="note-label">
                        备注：{{ scope.row[colIdx].remark }}
                      </p>
                      <p class="edit-wrap">
                        <i
                          style="padding: 0 8px; cursor: pointer"
                          class="el-icon-edit"
                          @click="handleNote(scope.row[colIdx], 2)"
                        ></i>
                        <el-popconfirm
                          title="确定删除此备注吗？"
                          @confirm="handleNote(scope.row[colIdx], 0)"
                        >
                          <i
                            slot="reference"
                            style="cursor: pointer"
                            class="el-icon-delete"
                          ></i>
                        </el-popconfirm>
                      </p>
                    </div>
                  </template>
                </el-table-column>
              </template>
              <el-table-column fixed="right" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="formDialog('edit', mFormData[scope.$index])"
                    v-authorizeRole="{
                      itemCode: 'crf_data_update',
                      menuContainer: $authorContainer,
                    }"
                  >
                    <i
                      class="iconfont icon-bianji"
                      style="color: #2c5c89"
                      title="编辑"
                    ></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="formDialog('check', mFormData[scope.$index])"
                    v-authorizeRole="{
                      itemCode: 'crf_data_see',
                      menuContainer: $authorContainer,
                    }"
                  >
                    <i
                      class="iconfont icon-list"
                      style="color: #2c5c89"
                      title="查看"
                    ></i>
                  </el-button>
                  <el-button
                    :disabled="mFormData[scope.$index].status == 1"
                    type="text"
                    size="small"
                    v-authorizeRole="{
                      itemCode: 'crf_data_del',
                      menuContainer: $authorContainer,
                    }"
                  >
                    <el-popconfirm
                      title="您确定要删除该数据吗？"
                      :disabled="mFormData[scope.$index].status == 1"
                      @confirm="removeForm(mFormData[scope.$index].id)"
                    >
                      <i
                        slot="reference"
                        class="iconfont icon-shanchu"
                        :class="[
                          mFormData[scope.$index].status == 1
                            ? 'disable-icon'
                            : 'icondefault',
                        ]"
                        title="删除"
                      ></i>
                    </el-popconfirm>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="text-align: right; margin-top: 10px">
              <el-button
                v-if="
                  cols &&
                  cols.length > 0 &&
                  handleItem.allowAdd == 'true' &&
                  handleItem.isEpro != '1'
                "
                :disabled="handleItem.status !== 0"
                type="primary"
                size="small"
                v-authorizeRole="{
                  itemCode: 'crf_save',
                  menuContainer: $authorContainer,
                }"
                @click="formDialog('add')"
              >
                新增
              </el-button>
              <el-button
                type="primary"
                @click="handleSubmitConfirm()"
                size="small"
                v-authorizeRole="{
                  itemCode: 'crf_submit',
                  menuContainer: $authorContainer,
                }"
                :disabled="handleItem.status !== 0"
                v-if="isShowBTN == 0"
                :loading="submitLoading"
              >
                提交
              </el-button>
            </div>
          </div>
          <div v-if="formDialogVisible">
            <!-- 添加多表 -->
            <el-dialog
              :title="textMap[dialogStatus]"
              :visible.sync="formDialogVisible"
              custom-class="center-dialog"
            >
              <FormEntryForm
                :formData="dynamicForm"
                ref="multiForm"
                :ddValueIds="ddValueIds"
                :exampleId="exampleId"
                :isShowBTN="isShowBTN"
                :itemStatus="itemStatus"
                :dialogStatus="dialogStatus"
                formType="Multiple"
                :sectionList="sectionList"
                :parentExampleId="parentExampleId"
                :isIndex="isIndex"
                :researchCode="researchCode"
                :editKey="editKey"
                @closeFrom="closeFrom"
                :handleItem="handleItem"
                :changePatientId="changePatientId"
              ></FormEntryForm>
            </el-dialog>
          </div>
        </el-card>
      </el-col>
      <!-- 右边留白
            <el-col :span="4"></el-col> -->
    </el-row>
    <el-dialog
      :title="questionTitle"
      :visible.sync="questionDialogShow"
      :close-on-click-modal="true"
    >
      <div v-if="questionDialogShow">
        <QuestionToast
          :qaIndex="qaIndex"
          :btnSendLoading="btnSendLoading"
          :btnCloseLoading="btnCloseLoading"
          ref="qtost"
          :leftQAList="leftQAList"
          @onSendquestion="onSendquestion"
          @addNewQA="addNewQA"
          @onCloseQuestion="onCloseQuestion"
          @setIndex="setIndex"
          :tloading="tloading"
        ></QuestionToast>
      </div>
    </el-dialog>
    <!-- 添加访视 -->
    <el-dialog
      :title="visitTitle"
      :visible.sync="visibleAdd"
      width="28%"
      custom-class="visit-dialog"
      @open="submitButtonDisabled = false"
      ><!--:rules="visitRulesAdd"-->
      <AddVisitForm
        @cancelToast="cancelToast"
        @addVisitFormConfirm="addVisitFormConfirm"
        @pickTime="pickTime"
        :submitButtonDisabled="submitButtonDisabled"
        :visitFormAdd="visitFormAdd"
        :visibleAdd="visibleAdd"
      ></AddVisitForm>
    </el-dialog>
    <el-dialog title="备注" :visible.sync="noteDialogVisible" width="30%">
      <el-form :model="noteForm" label-width="auto">
        <el-form-item>
          <el-input
            type="textarea"
            v-model="noteForm.remark"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="noteDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="onSubmitNote"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="痕迹记录"
      :visible.sync="tarilDialogTableVisible"
      width="40%"
    >
      <div
        class="infinite-list-wrapper"
        style="overflow: auto"
        v-if="tarilDialogTableVisible"
      >
        <ul
          class="list"
          v-infinite-scroll="listLoad"
          infinite-scroll-disabled="disabled"
        >
          <li
            v-for="(trail, i) in ctrailList"
            class="list-item"
            :key="trail.pkId"
          >
            <div class="item-wrap">
              <p>
                <el-avatar icon="el-icon-user-solid"></el-avatar>
                <span style="font-weight: 800; margin-left: 12px">{{
                  trail.userName
                }}</span>
              </p>
              <p>{{ trail.createTime }}</p>
            </div>
            <div
              class="item-msg-wrap"
              :class="{ noline: i == trailList.list.length - 1 }"
            >
              {{ formatterDate(trail) }}
            </div>
          </li>
        </ul>
        <p class="dialog-tip d-t-loading" v-if="listLoading">加载中...</p>
        <p class="dialog-tip d-t-nomore" v-if="noMore">没有更多了</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSectionList,
  getSectionTree,
  getTesteeList,
  getVisitList,
  getFormList,
  getTreeNodeData,
  addFormEntry,
  getStructureDdList,
  deleteFormEntry,
  markSection,
  buttonSDV,
  getAllQuestionList,
  stopQuestionList,
  addQuestionList,
  addQuestion,
  submit,
  editRemark,
} from "@/api/formEntry";
import { getVisitPlanInfo, addPatientActualTime } from "@/api/visit";
import {
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import { handleFormData } from "@/utils/index";
import { Loading } from "element-ui";
import FormEntryForm from "../components/FormEntryForm";
import FormEntryFormSingle from "../components/FormEntryFormSingle";
import QuestionToast from "../components/QuestionToast";
import AddVisitForm from "../components/testee/AddVisitForm";
import { mapActions, mapMutations, mapState } from "vuex";

let loadingInstance = null;
export default {
  name: "FormEntry",
  components: {
    FormEntryForm,
    FormEntryFormSingle,
    QuestionToast,
    AddVisitForm,
  },
  filters: {
    treeNodeNameEllipsis(name) {
      if (name && name.length > 8) return name.slice(0, 8) + "...";
      return name;
    },
  },
  computed: {
    ...mapState(["trailList", "userData"]),
    multiFormParam() {
      return {
        registryEntityCode: this.researchCode,
        selectionType: this.handleItem.selectionType,
        code: this.handleItem.code,
        patientId: this.changePatientId,
        exampleId: this.handleItem.exampleId,
        parentCode: this.handleItem.parentCode,
      };
    },
    noMore() {
      return (
        Math.ceil(this.pageConfig.count / this.pageConfig.size) <=
        this.pageConfig.current
      );
    },
    disabled() {
      return this.listLoading || this.noMore;
    },
  },
  watch: {
    qaIndex: function (o, n) {
      this.setSend(false);
    },
  },
  data() {
    return {
      // noMore:false,
      currentMap: {},
      formLoading: true,
      ctrailList: [],
      listLoading: false,
      tarilDialogTableVisible: false,
      noteForm: {},
      noteDialogVisible: false,
      changeLoading: true,
      nextItem: {},
      vnode: {},
      visitTitle: "添加访视",
      visibleAdd: false,
      submitButtonDisabled: false,
      visitFormAdd: {
        visitDate: "",
        name: "",
        type: "",
        plannedTime: "",
        visitDateStatus: "",
        //访视窗之前时间
        visitDateBefor: "",
        //访视窗之后时间
        visitDateAfter: "",
        sCode: "",
        patientId: "",
        sGroup: "",
      },
      isShowBTN: 0,
      submitLoading: false,
      submitObj: {},
      qaIndex: 0,
      questionDialogShow: false,
      questionTitle: "质疑",
      leftQAList: [],
      btnSendLoading: false,
      btnCloseLoading: false,
      addQAItem: {},
      tloading: true,
      formDtataList: [],
      currentMultiFormItemNum: 0,
      itemStatus: "", //重复条目的状态,1 表示是默认值生成的,不可删除
      handleItem: {},
      treeData: [],
      isOnload: false,
      // 左侧下拉及表单表头
      projectName: this.$route.query.researchName,
      centerName: this.$route.query.centerName,
      testeeNumber: this.$route.query.testeeName,
      visitName: "",
      headerFormName: "",
      testeeValue: "",
      visitValue: "",
      formValue: "",
      visitItemData: null,
      testeeList: [],
      visitList: [],
      currentMultiOperate: "",
      currentMultiRowId: "",
      regionOptions: regionDataPlus,
      dialogStatus: "",
      textMap: {
        update: "编辑表单",
        create: "新增表单",
        check: "查看表单",
      },
      formDialogVisible: false,
      loading: false,
      researchCode: this.$route.query.researchCode,
      researchName: this.$route.query.researchName,
      parentCode: this.$route.query.parentCode,
      patientId: this.$route.query.patientId,
      exampleId: this.$route.query.exampleId, // 暂无
      ddValueIds: "",
      changePatientId: this.$route.query.patientId,
      outpatientNumber: this.$route.query.outpatientNumber,
      defaultCheckTreeNode: "",
      isIndex: 0,
      sectionList: [],
      dynamicForm: {
        singleSectionConditionMap: {},
        sectionDDMap: {},
        domains: [],
      },
      // 多表
      cols: [],
      mFormData: [],
      dataMap: {},
      editKey: "",
      parentExampleId: "",
      // 上传文件
      currentSectionType: "",
      formStatus: 0,
      formStatusLabel: ["", "未做/未检测", "不知道", "尚未获取", "不适用"],
      trailLoading: false,
      currentTrail: {},
      pageConfig: {
        current: 0,
        size: 5,
        count: 5,
      },
      plannedVisitInfo: {}, //计划访视info
    };
  },
  created() {
    this.exampleId = this.$route.query.exampleId;
    loadingInstance = Loading.service({ fullscreen: true, text: "Loading" });
    this.getTestee();
    this.getSectionTrees();
  },
  methods: {
    ...mapActions(["onGetTrailList"]),
    ...mapMutations(["clearTrailList"]),
    formatterDate(v) {
      if (v.controlType === "ADSEL") {
        let addr = "";
        v.dataNew.split(",").map((e) => {
          addr += `${CodeToText[e]} `;
        });
        return v.prefix + addr;
      } else {
        return v.prefix + v.dataNew;
      }
    },
    onTrailList(v) {
      this.currentTrail = {
        patientId: v.patientId,
        ddCode: v.ddCode,
        sectionCode: v.sectionCode,
        exampleId: v.exampleId,
        submit: this.isShowBTN ? 1 : 0,
      };
      this.pageConfig.current = 0;
      this.listLoad();
      this.tarilDialogTableVisible = true;
    },
    setTarilDialogTableVisible() {
      this.pageConfig.count = this.trailList.count;
      this.trailLoading = false;
      this.ctrailList = this.trailList.list;
      this.listLoading = false;
    },
    listLoad() {
      this.trailLoading = true;
      this.listLoading = true;
      this.pageConfig.current++;
      this.onGetTrailList({
        obj: {
          ...this.currentTrail,
          ...this.pageConfig,
          userName: this.userData.userName,
        },
        cb: this.setTarilDialogTableVisible,
      });
    },

    handleNote(v, t) {
      if (v.remark && t == 1) return;
      this.noteForm = {
        pkId: v.pkId,
        remark: v.remark,
        submit: this.isShowBTN,
        model: t ? "UPDATE" : "DELETE",
        sectionCode: v.sectionCode,
      };
      if (t == 0) {
        this.onSubmitNote();
        return;
      }
      this.noteDialogVisible = true;
    },
    onSubmitNote() {
      editRemark({ ...this.noteForm, userName: this.userData.userName })
        .then((res) => {
          if (res.code == 200) {
            this.refresh();
            this.noteDialogVisible = false;
          }
        })
        .catch((err) => {});
    },
    formatterCurrentCode() {
      return "";
    },
    cancelToast() {
      this.visibleAdd = false;
    },
    addVisitFormConfirm() {
      let postData = {
        patientId: this.visitFormAdd.patientId,
        registryEntityCode: this.researchCode,
        sCode: this.visitFormAdd.sCode,
        status: this.visitFormAdd.visitDateStatus,
        actualTime: this.visitFormAdd.visitDate,
        sName: this.visitFormAdd.name,
        sGroup: this.visitFormAdd.sGroup,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      addPatientActualTime(postData)
        .then((res) => {
          this.$message({
            type: "success",
            showClose: true,
            message: res.msg,
            center: true,
          });
          if (res.code == 200) {
            this.vnode.expanded = true;
          }
          this.visibleAdd = false;
        })
        .catch((error) => {
          this.visibleAdd = false;
        });
    },
    pickTime() {
      if (
        this.visitFormAdd.visitDateBefor &&
        this.visitFormAdd.visitDateAfter
      ) {
        let visitDateBefor = Date.parse(this.visitFormAdd.visitDateBefor);
        let visitDateAfter = Date.parse(this.visitFormAdd.visitDateAfter);
        let visitDate = Date.parse(val);
        if (visitDate >= visitDateBefor && visitDate <= visitDateAfter) {
          this.visitFormAdd.visitDateStatus = "1";
        } else {
          this.visitFormAdd.visitDateStatus = "2";
        }
      }
    },
    setIndex(i) {
      this.qaIndex = i;
    },
    setSend(bool) {
      this.$set(this.leftQAList[this.qaIndex], "canSend", bool);
    },
    onChangeSDV(item) {
      buttonSDV({ pkId: item.pkId + "", flag: `${!item.sdvStatus}` })
        .then((res) => {
          item.sdvStatus = !item.sdvStatus;
        })
        .catch((err) => {});
    },
    onQuestion(item) {
      this.addQAItem = item;
      this.questionDialogShow = true;
      this.questionTitle = `质疑：${item.ddName}`;
      this.leftQAList = [];
      getAllQuestionList({
        exampleId: item.exampleId,
        ddCode: item.ddCode,
        roleId: localStorage.getItem("user"),
      })
        .then((res) => {
          if (res.code === 200) {
            this.leftQAList = res.data;
            this.tloading = false;
            if (this.leftQAList.length) {
              this.setSend(false);
            }
            this.$refs.qtost.onScrollBottom();
            this.refashZY();
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((error) => {
          this.tloading = false;
        });
    },
    onSendquestion({ item, cb, i, user }) {
      this.questionDialogShow = true;
      this.btnSendLoading = true;
      addQuestion({
        valueId: item.code,
        content: item.content,
        b: item.b,
        solve: user,
        creatRole: this.$authorContainer.roleId,
      })
        .then((res) => {
          this.leftQAList = res.data;
          this.setSend(false);
          this.btnSendLoading = false;
          cb();
          this.refashZY();
        })
        .catch((err) => {
          this.btnSendLoading = false;
        });
    },
    addNewQA({ addData, cb, user }) {
      let params = {
        ddCode: this.addQAItem["ddCode"],
        exampleId: this.addQAItem["exampleId"],
        registryEntityCode: this.addQAItem["registryEntityCode"],
        content: addData.content,
        solve: user,
        creatRole: localStorage.getItem("user"),
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      addQuestionList(params)
        .then((res) => {
          if (res.code === 200) {
            this.leftQAList = res.data;
            this.setSend(false);
            cb();
            this.refashZY();
          } else {
          }
        })
        .catch((err) => {});
    },
    onCloseQuestion(item) {
      let params = {
        id: item.id,
      };
      this.btnCloseLoading = true;
      stopQuestionList({ id: item.code })
        .then((res) => {
          this.btnCloseLoading = false;
          this.leftQAList = res.data;
          this.setSend(false);
          if (res.code === 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    onConfimNextPage() {
      this.$confirm("跳转下一表单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: false,
      })
        .then(() => {
          this.handleNodeClick(this.nextItem);
        })
        .catch(() => {
          this.refresh();
        });
    },
    handleSubmitConfirm(params) {
      let obj = {
        researchCode: this.$route.query.researchCode,
        sectionCode: this.handleItem.code,
        patientId: this.changePatientId,
        exampleId: this.handleItem.exampleId,
        parentExampleId: this.parentExampleId,
        submit: "1",
        userName: this.userData.userName,
      };
      this.submitLoading = true;
      submit(obj)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              showClose: true,
              message: res.data,
              center: true,
            });
            if (this.nextItem) {
              this.onConfimNextPage();
            }
            // localStorage.getItem('isNext') && localStorage.getItem('isNext') ==1 && this.refresh()
          }
          this.submitLoading = false;
        })
        .catch((err) => {
          this.$message({
            type: "error",
            showClose: true,
            message: "提交失败",
            center: true,
          });
          this.submitLoading = false;
        });
    },
    formatterScope(scope, colIdx) {
      if (
        scope.row[colIdx].controlType === "ADSEL" &&
        scope.row[colIdx].value
      ) {
        let addr = "";
        scope.row[colIdx].value.split(",").map((e) => {
          addr += `${CodeToText[e]} `;
        });
        return addr;
      }
      if (scope.row[colIdx].controlType === "SEL_SIG") {
        let arr = scope.row[colIdx].itemOptionList.filter((e) => {
          return e.value == scope.row[colIdx].value;
        });
        if (arr.length) {
          return arr[0].displayName;
        }
      } else {
        return scope.row[colIdx].value;
      }
    },
    updateDynamicForm(d) {
      this.dynamicForm = d;
    },
    // 首次进入左侧下拉显示设置
    leftSelectsDefault() {
      try {
        this.testeeValue =
          this.testeeList.filter(
            (item) => item.outpatientNumber == this.outpatientNumber
          ).length &&
          this.testeeList.filter(
            (item) => item.outpatientNumber == this.outpatientNumber
          )[0].pkId;
      } catch (error) {}
    },
    //左侧3下拉改变
    testeeChange(val) {
      let visitData = this.testeeList.filter((item) => item.pkId === val)[0];
      this.testeeNumber = visitData.name;
      this.outpatientNumber = visitData.outpatientNumber;
      this.changePatientId = visitData.pkId;
      this.getSectionTrees();
      this.getTreeNode(this.handleItem);
    },
    //左侧3下拉数据获取
    getTestee() {
      let obj = {
        researchCode: this.$route.query.researchCode,
        centerKey: this.$route.query.centerKey,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };

      getTesteeList(obj)
        .then((res) => {
          this.testeeList = res.data;

          this.leftSelectsDefault();
          // loadingInstance.close();
        })
        .catch((error) => loadingInstance.close());
    },
    getVisit(pkId, isLeftDefault) {
      let obj = {
        researchCode: this.$route.query.researchCode,
        centerKey: this.$route.query.centerKey,
        pkId: pkId,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      getVisitList(obj)
        .then((res) => {
          this.visitList = res.data;
          if (isLeftDefault) {
            this.visitItemData = this.visitList.filter(
              (item) => item.exampleId === this.$route.query.exampleId
            )[0];
            this.visitName = this.visitItemData.name;
            this.visitValue = this.visitItemData.exampleId;
            this.changePatientId = this.visitItemData.patientId;
            // this.parentExampleId = this.visitItemData.exampleId;
            this.getForm(this.visitValue, true);
          }
        })
        .catch((error) => loadingInstance.close());
    },
    getForm(exampleId, isLeftDefault) {
      let obj = {
        registryEntityCode: this.$route.query.researchCode,
        code: this.visitItemData.code,
        pkId: this.visitItemData.patientId,
        exampleId: exampleId,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      this.parentExampleId = this.visitItemData.exampleId;
      getFormList(obj)
        .then((res) => {
          this.sectionList = res.data.sectionList;
          if (isLeftDefault) {
            this.formValue = this.sectionList[0].code;
            this.headerFormName = this.sectionList[0].name;
            this.getTreeNode(this.isIndex);
            loadingInstance.close();
          }
        })
        .catch((error) => loadingInstance.close());
    },
    // 标记
    async markStatus(val) {
      let obj = {
        registryEntityCode: this.researchCode,
        sectionCode: this.handleItem.code,
        patientId: this.changePatientId,
        exampleId: this.handleItem.exampleId,
        parentExampleId: this.parentExampleId,
        status: val,
        sectionType: this.handleItem.selectionType,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      await markSection(obj)
        .then((res) => {
          this.$errInfo(val == 0 ? "取消标记成功" : res.data, "success");
          this.clearFormEntry(true);
          this.mFormData = [];
          this.handleNodeClick(this.handleItem, true);
        })
        .catch((e) => {
          this.handleItem.status = 0;
        });

      if (val === 0) {
        this.handleItem.status = 0;
      }
    },
    clearFormEntry(hided) {
      if (this.$refs.singleForm) this.$refs.singleForm.clearFormValid(hided);
      if (this.$refs.multiForm) this.$refs.multiForm.clearFormValid(hided);
    },
    //获取左侧树
    getSectionTrees() {
      let postData = {
        registryEntityCode: this.$route.query.registryEntityCode,
        patientId: this.changePatientId,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
        patientGroup: this.$route.query.groupConfigLists
          ? JSON.parse(this.$route.query.groupConfigLists).join(",")
          : "",
        // centerKey: this.$route.query.centerKey,
      };
      getSectionTree(postData)
        .then((res) => {
          this.treeData = res.data.data;
          console.log("this.treeData", this.treeData);
          let firstVisit = this.treeData.filter(
            (_) => _.code === this.parentCode
          )[0];
          console.log("firstVisit", firstVisit);
          this.visitName = firstVisit.displayNameCn;
          if (firstVisit.children && firstVisit.children.length > 0) {
            this.headerFormName = firstVisit.children[0].displayNameCn;
            this.defaultCheckTreeNode = firstVisit.children[0].code;

            this.getActualTime(firstVisit);
            this.handleNodeClick(firstVisit.children[0]);
          } else this.handleItem.status = 0;
          loadingInstance.close();
          this.formatterCurrentCode();
        })
        .catch((error) => {
          loadingInstance.close();
        });
    },
    treeNodeTextHandle(data, node) {
      let text = data.displayNameCn;
      return text.length > 10 ? text.slice(0, 8) + "..." : text;
    },
    // 判断是否是数组
    isArrayFn(value) {
      if (typeof Array.isArray === "function") {
        return Array.isArray(value);
      } else {
        return Object.prototype.toString.call(value) === "[object Array]";
      }
    },
    // 获取实际访视时间
    getActualTime(visitItem) {
      const resData = {
        patientId: visitItem.patientId,
        registryEntityCode: visitItem.registryEntityCode,
        sCode: visitItem.code,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      getVisitPlanInfo(resData).then((res) => {
        this.plannedVisitInfo = res.data || {};
      });
    },
    // 表单设置sdv
    setFormSdv() {
      // // let formDtataList = this.formDtataList || [];
      // // console.log("this.formDtataList", this.formDtataList)
      // console.log("this.dynamicForm ", this.dynamicForm);
      // // formDtataList.forEach((item) => {
      // //   item.forEach((childItem) => {
      // //     if (childItem.sdv === "true" && !childItem.sdvStatus) {
      // //       this.onChangeSDV(childItem);
      // //     }
      // //   });
      // // });
      let sectionDDMap = (this.dynamicForm || {}).sectionDDMap || [];
      if (!this.isArrayFn(sectionDDMap)) return;
      sectionDDMap.forEach((item) => {
        // console.log("item", item);
        if (item.isRepeat === "0") {
          item.label.forEach((labelItem) => {
            let info = item.groupMap[`${labelItem.ddCode}`];
            if (info.sdv === "true" && !info.sdvStatus) {
              this.onChangeSDV(info);
            }
          });
        } else if (item.isRepeat === "1") {
          item.mFormData.forEach((mFormItem) => {
            let infoList = item.groupMap[`${mFormItem.id}`] || [];
            infoList.forEach((infoItem) => {
              if (infoItem.sdv === "true" && !infoItem.sdvStatus) {
                this.onChangeSDV(infoItem);
              }
            });
          });
        }
      });
    },
    initData(visitItem) {
      const resData = {
        patientId: visitItem.patientId,
        registryEntityCode: visitItem.registryEntityCode,
        sCode: visitItem.code,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      getVisitPlanInfo(resData).then((res) => {
        this.visitFormAdd.plannedTime = res.data.plannedTime;
        this.visitFormAdd.visitDateAfter = res.data.max ? res.data.max : "";
        this.visitFormAdd.visitDateBefor = res.data.min ? res.data.min : "";
      });
      //填写访视
      this.visitFormAdd = this.$options.data.call(this).visitFormAdd;
      this.$refs.visitFormAdd && this.$refs.visitFormAdd.clearValidate();
      this.visitFormAdd.name = visitItem.name;
      this.visitFormAdd.type = visitItem.type;
      this.visitFormAdd.sCode = visitItem.code;
      this.visitFormAdd.patientId = visitItem.patientId;
      this.visitFormAdd.visitDate = visitItem.actualTime
        ? visitItem.actualTime
        : "";
      this.visitFormAdd.sGroup = visitItem.groupPatientList
        ? visitItem.groupPatientList.join(",")
        : "";
    },
    checkChange(a, b, c) {
      if (a.skipFlag == 0) {
        b.expanded = false;
      } else {
        if (a.actualTimeFlag == 0 && a.type !== "2") {
          b.expanded = false;
          this.visibleAdd = true;
          this.initData(a);
          this.vnode = b;
        } else {
          b.expanded = true;
        }
      }
    },
    //左侧树节点点击事件
    async handleNodeClick(item, clearSingleForm) {
      if (item.parentCode == "Root") {
        // console.log("item", item)

        return;
      }
      localStorage.removeItem("sectionDDMap");
      localStorage.setItem("handleItem", JSON.stringify(item));
      // localStorage.setItem('clearSingleForm',JSON.stringify(clearSingleForm))
      this.handleItem = item;

      this.treeData.map((e) => {
        e.children.map((f, i, arr) => {
          if (f.code === item.code) {
            if (i < arr.length) {
              this.nextItem = e.children[i + 1];
            }
          }
        });
      });

      this.exampleId = this.handleItem.exampleId;
      this.parentExampleId = this.handleItem.parentExampleId;
      this.defaultCheckTreeNode = item.code;

      // this.$nextTick(()=>{
      //     let doms = document.getElementsByClassName('is-checked')
      //     Array.from(doms).map((e,i)=>{
      //         if (i === doms.length-1) {
      //             doms[doms.length-1].style.backgroundColor = '#f0f7ff'
      //         }else {
      //             e.style.backgroundColor = '#fff'
      //         }
      //     })
      // })

      if (item.parentCode !== "Root") {
        this.headerFormName = item.displayNameCn;
        let visistItem = this.treeData.filter(
          (_) => _.code === item.parentCode
        )[0];
        this.visitName = visistItem.displayNameCn;
        this.getTreeNode(item, clearSingleForm);
        this.getActualTime(visistItem);
        if (this.$refs["singleForm"])
          this.$refs["singleForm"].handleFirstIn(item);
        if (this.$refs["multiForm"])
          this.$refs["multiForm"].handleFirstIn(item);
      } else this.visitName = item.displayNameCn;
    },
    //质疑后刷新
    async refashZY(clearSingleForm) {
      this.handleItem = JSON.parse(localStorage.getItem("handleItem"));

      this.exampleId = this.handleItem.exampleId;
      this.parentExampleId = this.handleItem.parentExampleId;
      if (this.handleItem.parentCode !== "Root") {
        this.headerFormName = this.handleItem.displayNameCn;
        this.visitName = this.treeData.filter(
          (_) => _.code === this.handleItem.parentCode
        )[0].displayNameCn;
        this.getTreeNode(this.handleItem, clearSingleForm);
        if (this.$refs["singleForm"])
          this.$refs["singleForm"].handleFirstIn(this.handleItem);
        if (this.$refs["multiForm"])
          this.$refs["multiForm"].handleFirstIn(this.handleItem);
      } else this.visitName = this.handleItem.displayNameCn;
    },
    refresh(data) {
      this.getTreeNode(this.handleItem, "reAdd");
    },
    refreshTable(v) {
      if (this.currentSectionType === "Single") {
        let dataMap = this.currentMap;
        const groupCode = v.groupCode;
        const pkKey = Object.keys(v)[1];
        const pkValue = Object.values(v)[1];

        for (let k in dataMap) {
          let group = dataMap[k];
          if (group.groupCode === groupCode) {
            group.groupMap[pkKey] = pkValue;
          }
          for (let i in group.groupMap) {
            let item = group.groupMap[i];
            // handleFormData(item, "strToOther");
            if (item.controlType === "SUF") {
              item.value = item.value ? eval("(" + item.value + ")") : [];
              item.fileList = item.value;
            } else if (item.controlType == "NTX" && item.numConfig)
              item.numConfig = JSON.parse(item.numConfig);
            else if (item.controlType == "CB" && item.defaultValue) {
              item.value = item.defaultValue.split(",");
            }

            // try {
            //      if (item.value && Array.isArray(JSON.parse(item.value))) {
            //         item.value = JSON.parse(item.value)[0]
            //         localStorage.setItem('sectionDDMap', JSON.stringify(
            //         JSON.parse(JSON.stringify(dataMap)).filter((e) => {
            //                 return e.isRepeat === '1'
            //             })
            //         ))
            //     }
            // } catch (error) {

            // }

            this.dynamicForm.singleSectionConditionMap[i] = {
              hided: item.visible == 0,
            };
          }

          if (group.isRepeat == 1) {
            this.dynamicForm.singleSectionConditionMap[group.groupCode] = {
              hided: group.visible == 0,
            };
          }
        }
      }
    },

    // 右侧结构
    getTreeNode(item, clearSingleForm) {
      this.isOnload = false;
      this.formLoading = true;
      this.$refs["dynamicForm"] && this.$refs["dynamicForm"].resetFields();

      let obj = {
        registryEntityCode: this.researchCode,
        selectionType: item.selectionType,
        code: item.code,
        parentCode: item.parentCode,
        roleId: localStorage.getItem("user"),
        versionCode: JSON.parse(localStorage.getItem("versionCode")),

        // viewLevel: 1,

        patientId: this.changePatientId,
        exampleId: item.exampleId,
        parentExampleId: this.parentExampleId,
      };
      getTreeNodeData(obj)
        .then((res) => {
          this.formLoading = false;
          this.currentSectionType = item.selectionType;
          this.isOnload = true;

          this.isShowBTN = parseInt(res.data.submit);
          localStorage.setItem(
            "sdv",
            JSON.stringify(parseInt(res.data.submit))
          );
          if (true != clearSingleForm) {
            this.handleItem.status = res.data.formStatus;
          }
          if (this.currentSectionType === "Single") {
            let dataMap = res.data.ddMap;
            this.currentMap = res.data.ddMap;
            for (let k in dataMap) {
              let group = dataMap[k];
              for (let i in group.groupMap) {
                let item = group.groupMap[i];
                // handleFormData(item, "strToOther");
                if (item.controlType === "SUF") {
                  item.value = item.value ? eval("(" + item.value + ")") : [];
                  item.fileList = item.value;
                } else if (item.controlType == "NTX" && item.numConfig)
                  item.numConfig = JSON.parse(item.numConfig);
                else if (item.controlType == "CB" && item.defaultValue) {
                  item.value = item.defaultValue.split(",");
                }

                try {
                  if (item.value && Array.isArray(JSON.parse(item.value))) {
                    item.value = JSON.parse(item.value)[0];
                    localStorage.setItem(
                      "sectionDDMap",
                      JSON.stringify(
                        JSON.parse(JSON.stringify(dataMap)).filter((e) => {
                          return e.isRepeat === "1";
                        })
                      )
                    );
                  }
                } catch (error) {}

                this.dynamicForm.singleSectionConditionMap[i] = {
                  hided: item.visible == 0,
                };
              }

              if (group.isRepeat == 1) {
                this.dynamicForm.singleSectionConditionMap[group.groupCode] = {
                  hided: group.visible == 0,
                };
                // res.data.ddList.map((e,i)=>{
                //     if (e.groupCode == group.groupCode) {
                //         let arr = e.value? JSON.parse(e.value): []
                //         arr.map((v,ix)=>{
                //             group.groupMap['group'+ix] = e
                //         })
                //     }
                // })
              }
            }
            if (clearSingleForm === "reAdd") {
              localStorage.setItem(
                "sectionDDMap",
                JSON.stringify(
                  JSON.parse(JSON.stringify(dataMap)).filter((e) => {
                    return e.isRepeat === "1";
                  })
                )
              );
              this.dynamicForm.sectionDDMap.map((e, i) => {
                if (e.isRepeat === "1") {
                  this.$set(
                    this.dynamicForm.sectionDDMap[i],
                    "groupMap",
                    JSON.parse(JSON.stringify(dataMap))[i].groupMap
                  );
                } else {
                  this.$set(
                    this.dynamicForm.sectionDDMap[i],
                    "groupMap",
                    JSON.parse(JSON.stringify(dataMap))[i].groupMap
                  );
                }
              });
            } else {
              this.dynamicForm.sectionDDMap = JSON.parse(
                JSON.stringify(dataMap)
              );
            }
          } else if (this.currentSectionType === "Multiple") {
            this.cols = res.data.label;
            this.formDtataList = [...Object.values(res.data.dataMap)];
            localStorage.setItem(
              "formDtataList",
              JSON.stringify(this.formDtataList)
            );
            this.dataMap = res.data.dataMap;

            if (Object.keys(res.data.dataMap).length > 0) {
              let formList = [];
              Object.keys(res.data.dataMap).forEach((key) => {
                let obj = {};
                let code = "";
                res.data.dataMap[key].forEach((item) => {
                  code = item.ddCode;
                  if (item.controlType === "SEL_SIG") {
                    let selObj = item.itemOptionList.filter(
                      (v) => item.value === v.value
                    );
                    obj[code] =
                      selObj && selObj.length > 0 ? selObj[0].displayName : "";
                  } else if (item.controlType === "ADSEL") {
                    let addressArr = "";

                    if (item.value) {
                      item.value.split(",").forEach((item) => {
                        addressArr += CodeToText[item];
                      });
                    }
                    obj[code] = addressArr;
                  } else if (item.controlType == "NTX" && item.numConfig) {
                    item.numConfig = JSON.parse(item.numConfig);
                    obj[code] = item.value;
                  } else {
                    obj[code] = item.value;
                  }
                  obj["id"] = key;
                  obj["pkId"] = item.pkId || "";
                  obj["status"] = item.status || "";
                  obj["ddValueIds"] = item.ddValueIds || [];
                  obj["sdv"] = item.sdv;
                  obj["sdvStatus"] = item.sdvStatus || "";
                  obj["sdvValue"] = item.sdvValue;
                  obj["status"] = item.status;
                });
                formList.push(obj);
              });
              this.mFormData = formList;
            } else {
              this.mFormData = [];
            }
            this.currentMultiFormItemNum = this.mFormData.length;
          }
          loadingInstance.close();
        })
        .catch((error) => {
          this.formLoading = false;
          loadingInstance.close();
        });
    },
    // 多表
    tableHeaderCn() {
      return "table_header";
    },
    /**
     * formDialog: 打开弹出层,重复表单获取表单信息
     * param :
     */
    formDialog(val, data) {
      this.formDtataList = JSON.parse(localStorage.getItem("formDtataList"));
      if (val === "add") {
        if (this.currentMultiFormItemNum >= 40) {
          this.$message.error("最多重复 40 次!");
          return false;
        }
        getStructureDdList(this.multiFormParam).then((res) => {
          this.dialogStatus = "create";
          let dataMap = res.data.ddMap;
          for (let k in dataMap) {
            let item = dataMap[k];
            item.value = "";
            handleFormData(item, "strToOther");
            this.dynamicForm.singleSectionConditionMap[k] = {
              hided: item.visible == 0,
            };
          }
          this.dynamicForm.sectionDDMap = dataMap;
          this.formDialogVisible = true;
        });
      } else if (val === "edit") {
        let key = data.id;
        this.editKey = key;
        this.dialogStatus = "update";
        this.exampleId = key;
        this.ddValueIds = data.ddValueIds;
        this.itemStatus = data.status;
        let editCon = Object.assign({}, this.dataMap);
        this.dynamicForm.sectionDDMap = {};
        editCon[key].forEach((item) => {
          handleFormData(item, "strToOther");
          this.dynamicForm.singleSectionConditionMap[item.ddCode] = {
            hided: item.visible == 0,
          };
          this.dynamicForm.sectionDDMap[item.ddCode] = item;
          this.formDialogVisible = true;
        });
      }
      if (val === "check") {
        let key = data.id;
        let editCon = Object.assign({}, this.dataMap);
        editCon[key].forEach((item) => {
          handleFormData(item, "strToOther");
          this.dynamicForm.sectionDDMap[item.ddCode] = item;
          this.dynamicForm.singleSectionConditionMap[item.ddCode] = {
            hided: item.visible == 0,
          };
        });

        this.dialogStatus = "check";
        this.formDialogVisible = true;
      }
    },
    closeFrom(val, num) {
      if (num == 0 || num == 1) this.currentMultiFormItemNum += num;
      if (val) {
        // if (this.dialogStatus !== "check") {
        this.getTreeNode(this.handleItem);
        // }
        this.dynamicForm.sectionDDMap = {};
        this.dialogStatus = "";
        this.formDialogVisible = false;
      }
    },
    // 删除多表
    removeForm(id) {
      deleteFormEntry(id)
        .then((res) => {
          this.$message({
            type: "success",
            message: res.data,
          });
          this.getTreeNode(this.handleItem);
        })
        .catch((error) => {});
    },
    backtop() {
      let timer = setInterval(function () {
        let osTop = document.getElementsByClassName("el-card")[0].scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.getElementsByClassName("el-card")[0].scrollTop =
          osTop + ispeed;
        osTop === 0 && clearInterval(timer);
      }, 30);
    },
  },
  mounted() {
    setTimeout(() => {
      try {
        document.getElementsByClassName("jump-red")[0].parentNode.style.cursor =
          "not-allowed";
        document.getElementsByClassName(
          "jump-red"
        )[0].parentNode.style.backgroundColor = "rgba(0,0,0,0.03)";
        document.getElementsByClassName(
          "jump-red"
        )[0].previousElementSibling.style.cursor = "not-allowed";
        document.getElementsByClassName(
          "jump-red"
        )[0].previousElementSibling.style.pointerEvents = "none";
      } catch {}
    }, 500);
  },
  beforeRouteLeave(to, from, next) {
    loadingInstance && loadingInstance.close();
    next();
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-row {
  padding-right: 10px;
  padding-top: 10px;
  .el-col {
    overflow-y: scroll;
  }
}

/deep/ .el-card__body {
  padding: 10px !important;
}

/deep/ .el-tree-node__expand-icon.expanded {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

/deep/ .tree-line {
  .is-leaf:before {
    display: block;
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #2c5c89;
    color: #fff;
    line-height: 11px;
    font-size: 20px;
    text-align: center;
  }
  .jump-red {
    color: #ccc;
    cursor: not-allowed;
  }
  .el-tree-node {
    position: relative;
    padding-left: 15px;
  }
  .el-tree-node__children {
    padding-left: 15px;
  }
  .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: -3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #52627c;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child:before {
    height: 40px;
  }
  .el-tree-node::after {
    content: "";
    width: 24px;
    height: 20px;
    position: absolute;
    left: -3px;
    top: 13px;
    border-width: 1px;
    border-top: 1px dashed #52627c;
  }
  & > .el-tree-node:after {
    border-top: none;
  }
  & > .el-tree-node:before {
    border-left: none;
  }
}

.tree-color {
  background: green;
}

/deep/ .el-tree-node__expand-icon:before {
  display: block;
  content: "+";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #2c5c89;
  color: #fff;
  line-height: 11px;
  font-size: 20px;
  text-align: center;
}

/deep/ .expanded:before {
  content: "-";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #2c5c89;
  color: #fff;
  line-height: 11px;
  font-size: 20px;
  text-align: center;
}

#formEntry {
  height: 100%;
  .form-header {
    background: #e9ecef;
    border: 1px solid #e9ecef;
    border-right: none;
    border-bottom: none;
    border-radius: 2px;
    height: 120px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    li {
      width: 25%;
      height: 60px;
      line-height: 60px;
      align-items: center;
      text-align: center;
      border-right: 1px solid #e9ecef;
      border-bottom: 1px solid #e9ecef;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        background: #fff;
      }
    }
  }
  .form-title {
    text-align: center;
    font-size: 18px;
    background: #e9ecef;
    color: #212121;
    margin-bottom: 10px;
    min-height: 40px;
    line-height: 40px;
  }
  .formNav {
    padding: 0 16px;
    .formNav-label {
      display: block;
      font-size: 14px;
      color: #212121;
      line-height: 16px;
      margin: 10px 0;
    }
    .menu-list .nav-item {
      a {
        display: block;
        height: 40px;
        color: #444;
        line-height: 40px;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 400;
        cursor: pointer;
      }
      a.active,
      a:hover {
        color: #409eff;
      }
    }
  }
  .formStatus {
    // background: #f7f7f7;
    padding: 0 15px 0;
    // width: 300px;
    height: 50px;
    line-height: 50px;
  }
  .formCon {
    // background: #f7f7f7;
    padding-bottom: 48px;
    margin-top: 5px;
    .el-form-item {
      background: #ffffff;
      // padding: 0 15px 15px;
    }
    .el-input {
      width: 270px;
    }
    .el-textarea {
      width: 270px;
    }
    .formLabel {
      font-weight: bold;
      margin-bottom: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
}

.el-form-item {
  margin-bottom: 0;
}

/*#formEntry .el-dialog__footer {*/
/*padding: 0;*/
/*}*/
</style>
<style lang="scss">
.table_header {
  background-color: #e9ecef !important;
  border-right: none !important;
  color: #000 !important;
}

#formEntry {
  display: flex;
  flex-direction: column;
  .el-card {
    padding-bottom: 48px;
  }
}

.table-wrap {
  .tag-top-wrap {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
  }
}

.color-tree {
  position: relative;
  padding-left: 8px;
  color: #e6a23c;
}

.color-tip {
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
}
.item-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    display: flex;
    align-items: center;
  }
}
.infinite-list-wrapper {
  max-height: 520px;
  .dialog-tip {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #909399;
    padding-top: 20px;
  }
}
.item-msg-wrap {
  margin: 8px 0;
  margin-left: 20px;
  min-height: 40px;
  border-left: 2px solid #ccc;
  display: flex;
  align-items: center;
  padding: 0 8px;
  padding-left: 30px;
}
.noline {
  border-left: 0px solid #ccc;
}
.list {
  padding: 0 12px;
}
</style>
